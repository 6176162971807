import React, { useEffect } from "react";
import HomepageLayout from "src/layouts/homepage";
import "./dos.css"; // Import CSS file here

export default function DoNotSellInfo() {
    return (
        <HomepageLayout>
                <iframe src="https://app.termly.io/notify/7e71c5c7-5e8f-4929-846b-a8b949b64610" title="easyview"></iframe>
        </HomepageLayout>
    );
}
